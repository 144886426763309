// JsFromEnums CacheKey 4507147ab2afaea7960f803d33a77252
//
// DO NOT MODIFY: This file was automatically generated by JsFromEnums.
export const all = [
  {
    "title": "Restaurants",
    "links": [
      {
        "name": "Top US Restaurants",
        "path": "/restaurant-gift-cards"
      },
      {
        "name": "Fast Food",
        "path": "/fast-food-gift-cards"
      },
      {
        "name": "Food Delivery",
        "path": "/food-delivery-gift-cards"
      },
      {
        "name": "American",
        "path": "/american-restaurant-gift-cards"
      },
      {
        "name": "Fine Dining",
        "path": "/fine-dining-gift-cards"
      },
      {
        "name": "Bars",
        "path": "/bar-gift-cards"
      },
      {
        "name": "Breakfast & Brunch",
        "path": "/breakfast-brunch-gift-cards"
      }
    ]
  },
  {
    "title": "Spa & Beauty",
    "links": [
      {
        "name": "Nail Salons",
        "path": "/nail-salon-gift-cards"
      },
      {
        "name": "Spas",
        "path": "/day-spa-gift-cards"
      },
      {
        "name": "Massages",
        "path": "/massage-gift-cards"
      },
      {
        "name": "All Spa & Beauty",
        "path": "/spa-beauty-gift-cards"
      }
    ]
  },
  {
    "title": "Top Cities",
    "links": [
      {
        "name": "New York",
        "path": "/new-york-ny-gift-cards"
      },
      {
        "name": "San Francisco",
        "path": "/san-francisco-ca-gift-cards"
      },
      {
        "name": "Chicago",
        "path": "/chicago-il-gift-cards"
      },
      {
        "name": "Los Angeles",
        "path": "/los-angeles-ca-gift-cards"
      },
      {
        "name": "Washington",
        "path": "/washington-dc-gift-cards"
      },
      {
        "name": "Denver",
        "path": "/denver-co-gift-cards"
      },
      {
        "name": "Houston",
        "path": "/houston-tx-gift-cards"
      },
      {
        "name": "Boston",
        "path": "/boston-ma-gift-cards"
      },
      {
        "name": "San Diego",
        "path": "/san-diego-ca-gift-cards"
      },
      {
        "name": "Portland",
        "path": "/portland-or-gift-cards"
      },
      {
        "name": "All Cities",
        "path": "/top-cities"
      }
    ]
  },
  {
    "title": "Featured Brands",
    "links": [
      {
        "name": "Vrbo",
        "path": "/gift-card/vrbo"
      },
      {
        "name": "Allegiant Air",
        "path": "/gift-card/allegiant-air"
      },
      {
        "name": "JetBlue",
        "path": "/gift-card/jetblue"
      },
      {
        "name": "Louis Vuitton",
        "path": "/gift-card/louis-vuitton"
      },
      {
        "name": "Spirit Airlines",
        "path": "/gift-card/spirit-airlines"
      },
      {
        "name": "Aldi",
        "path": "/gift-card/aldi"
      },
      {
        "name": "McDonalds",
        "path": "/gift-card/mcdonalds"
      },
      {
        "name": "H-E-B",
        "path": "/gift-card/h-e-b"
      },
      {
        "name": "All Brands",
        "path": "/brands/top-brands-gift-cards"
      }
    ]
  },
  {
    "title": "Travel",
    "links": [
      {
        "name": "Airlines & Flights",
        "path": "/airline-gift-cards"
      },
      {
        "name": "Hotels",
        "path": "/hotel-gift-cards"
      },
      {
        "name": "Rideshare",
        "path": "/rideshare-gift-cards"
      },
      {
        "name": "Tours",
        "path": "/tour-gift-cards"
      },
      {
        "name": "Gas",
        "path": "/gas-gift-cards"
      },
      {
        "name": "Ski Resorts",
        "path": "/ski-resort-gift-cards"
      }
    ]
  },
  {
    "title": "Activities",
    "links": [
      {
        "name": "Top Activities",
        "path": "/activity-gift-cards"
      },
      {
        "name": "Amusement Parks",
        "path": "/amusement-park-gift-cards"
      },
      {
        "name": "Bowling",
        "path": "/bowling-gift-cards"
      },
      {
        "name": "Casinos",
        "path": "/casino-gift-cards"
      },
      {
        "name": "Golf",
        "path": "/golf-gift-cards"
      }
    ]
  },
  {
    "title": "Shopping",
    "links": [
      {
        "name": "Top Stores",
        "path": "/shopping-gift-cards"
      },
      {
        "name": "Department Store",
        "path": "/department-store-gift-cards"
      },
      {
        "name": "Home Decor",
        "path": "/home-decor-gift-cards"
      },
      {
        "name": "Grocery",
        "path": "/grocery-gift-cards"
      },
      {
        "name": "Pets",
        "path": "/pet-gift-cards"
      },
      {
        "name": "Men's Clothing",
        "path": "/mens-clothing-gift-cards"
      },
      {
        "name": "Women's Clothing",
        "path": "/womens-clothing-gift-cards"
      }
    ]
  },
  {
    "title": "Universal",
    "links": [
      {
        "name": "Cash Gifts & Cards",
        "path": "/lp/cash-gift-cards"
      },
      {
        "name": "E Gifts & E Cards",
        "path": "/lp/e-gift-cards"
      },
      {
        "name": "Textable Gifts",
        "path": "/lp/text-message-gift-cards"
      },
      {
        "name": "Email Gift Cards",
        "path": "/lp/email-gift-cards"
      },
      {
        "name": "Printable Gift Cards",
        "path": "/lp/printable-gift-cards"
      },
      {
        "name": "Mailable Gift Cards",
        "path": "/lp/mailable-gift-cards"
      },
      {
        "name": "Online Gift Cards",
        "path": "/lp/online-gift-cards"
      },
      {
        "name": "Digital Gift Cards",
        "path": "/lp/digital-gift-cards"
      },
      {
        "name": "Virtual Gift Cards",
        "path": "/lp/virtual-gift-cards"
      }
    ]
  }
]
