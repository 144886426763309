<script lang="ts" setup>
import MobileSidebarLink from '@consumer/modules/layout/MobileSidebarLink.vue'
import { myAccount } from '@consumer/api'

defineProps<{
  open: boolean
}>()

const emit = defineEmits<{ 'update:open': [boolean] }>()

function onClose () {
  emit('update:open', false)
}
</script>

<template>
  <div v-show="open" class="absolute inset-0 bg-white px-8 py-4">
    <div class="mb-48px">
      <MobileSidebarLink
        class="border-none"
        style="align-items: start; margin-top: 7px; min-height: 80px;"
        @click="onClose"
      >
        <GIcon name="carousel-left"/>
        My Account
      </MobileSidebarLink>

      <div class="flex flex-col">
        <MobileSidebarLink
          :href="myAccount.purchases.path()"
          class="border-none"
        >
          <GIcon name="new-cards" size="24px" color="navy"/>
          Purchases
        </MobileSidebarLink>
        <MobileSidebarLink
          :href="myAccount.profile.path()"
          class="border-none"
        >
          <GIcon name="new-user-list" size="24px" color="navy"/>
          Profile
        </MobileSidebarLink>
        <MobileSidebarLink
          :href="myAccount.billing.path()"
          class="border-none"
        >
          <GIcon name="new-credit-card" size="24px" color="navy"/>
          Billing
        </MobileSidebarLink>
        <MobileSidebarLink
          :href="myAccount.settings.path()"
          class="border-none"
        >
          <GIcon name="new-gear" size="24px" color="navy"/>
          Settings
        </MobileSidebarLink>

        <GDivider class="py-6"/>

        <MobileSidebarLink
          href="/signout"
          class="border-none"
        >
          <GIcon name="new-sign-out" size="24px" color="navy"/>
          Log Out
        </MobileSidebarLink>
      </div>
    </div>
  </div>
</template>
