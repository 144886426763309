<script setup lang="ts">import { toRef as _toRef } from 'vue';

import { useSharedData } from '@consumer/composables/sharedData'

const __$temp_1 = (useSharedData()),
  currentUser = _toRef(__$temp_1, 'currentUser');
</script>

<template>
  <div
    v-if="currentUser"
    class="
      rounded-1 bg-primary-base text-white text-sm w-7 h-7 flex items-center justify-center
      font-semibold
    "
  >
    {{ currentUser?.initials }}
  </div>
</template>
