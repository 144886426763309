<script lang="ts" setup>import { toRef as _toRef } from 'vue';

import { Dialog, TransitionChild, TransitionRoot } from '@headlessui/vue'
import giftlyLogoDefault from '@consumer/assets/images/giftly-logo.svg'
import searchIcon from '@consumer/assets/images/search-icon.webp'
import { useSharedData } from '@consumer/composables/sharedData'
import MobileSidebarMyAccount from '@consumer/modules/layout/MobileSidebarMyAccount.vue'
import MobileSidebarLink from '@consumer/modules/layout/MobileSidebarLink.vue'
import UserInitial from '@consumer/modules/layout/UserInitial.vue'
import { openHowGiftlyWorksModal } from '@consumer/services/modals'
import { all as categories } from '@consumer/enums/Consumer/NavCategories'
import MobileSidebarList from './MobileSidebarList.vue'

defineProps<{
  open: boolean
}>()

const __$temp_1 = (useSharedData()),
  currentUser = _toRef(__$temp_1, 'currentUser'),
  activeHoliday = _toRef(__$temp_1, 'activeHoliday');

const emit = defineEmits(['close'])

const closeButton = ref<HTMLButtonElement>()

const categoriesOpen = ref(false)
const giftIdeasOpen = ref(false)
const myAccountOpen = ref(false)

const onClose = () => {
  categoriesOpen.value = false
  giftIdeasOpen.value = false
  emit('close')
}

const onClickHowItWorks = () => {
  onClose()
  openHowGiftlyWorksModal({ trackNavLinkClick: true })
}
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="fixed inset-0 flex z-1000 lg:hidden justify-end"
      :initialFocus="closeButton"
      @close="onClose"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div class="fixed inset-0"/>
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div class="relative flex flex-col w-full h-screen bg-white text-16px mobile-sidebar">
          <button
            ref="closeButton"
            type="button"
            class="absolute right-4 p-5 outline-none ring-0 ring-0:active z-1000"
            style="top: 22px;"
            @click="onClose"
          >
            <span class="sr-only">Close sidebar</span>
            <GIcon name="close" size="12px" color="grey-700"/>
          </button>
          <div class="bg-white h-screen flex flex-col">
            <div class="w-full px-8 py-4 flex-1">
              <a href="/" class="block py-5" style="margin-bottom: 18px;">
                <img width="65" height="30" :src="giftlyLogoDefault" alt="Giftly">
              </a>

              <div>
                <MobileSidebarLink expandable trackGa="open_nav_categories" @click="categoriesOpen = true">
                  Gift Card Categories
                </MobileSidebarLink>
                <MobileSidebarLink
                  href="/browse"
                >
                  Browse
                </MobileSidebarLink>
                <MobileSidebarLink
                  v-if="activeHoliday === 'holiday'"
                  href="/browse/christmas-gifts"
                >
                  Holiday Gift Cards
                </MobileSidebarLink>
                <MobileSidebarLink
                  v-if="activeHoliday === 'mothers_day'"
                  href="/browse/mothers-day-gifts"
                >
                  Mother's Day Gift Cards
                </MobileSidebarLink>
                <MobileSidebarLink
                  v-if="activeHoliday === 'fathers_day'"
                  href="/browse/fathers-day-gifts"
                >
                  Father's Day Gift Cards
                </MobileSidebarLink>
                <MobileSidebarLink @click="onClickHowItWorks">
                  How It Works
                </MobileSidebarLink>
                <MobileSidebarLink href="/gift-cards">
                  Search
                  <template #right>
                    <img
                      :src="searchIcon"
                      alt="Search"
                      width="19"
                      height="17"
                      class="transform duration-300 ease-in-out hover:scale-130"
                    >
                  </template>
                </MobileSidebarLink>
              </div>
            </div>

            <div class="bg-grey-200 px-32px flex flex-col fixed bottom-0 left-0 right-0">
              <MobileSidebarLink
                href="/entercode"
                alt="Redeem"
                class="border-none"
              >
                Redeem
              </MobileSidebarLink>
              <MobileSidebarLink
                href="/corp"
                alt="Corporate"
                class="border-none"
              >
                Corporate
              </MobileSidebarLink>
              <MobileSidebarLink
                href="/support"
                alt="Help"
                class="border-none"
              >
                Help
              </MobileSidebarLink>

              <MobileSidebarLink
                v-if="currentUser?.corporate"
                href="/corp/dashboard"
                alt="Corporate Dashboard"
                class="border-none"
              >
                Dashboard
              </MobileSidebarLink>
              <MobileSidebarLink
                v-if="currentUser"
                expandable
                @click="myAccountOpen=true"
              >
                <UserInitial/>
                My Account
              </MobileSidebarLink>
              <template v-else>
                <MobileSidebarLink href="/login" alt="Login">Login</MobileSidebarLink>
              </template>
            </div>

          </div>
          <MobileSidebarList v-model:open="categoriesOpen" title="Categories" :lists="categories"/>
          <MobileSidebarMyAccount v-model:open="myAccountOpen"/>
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>
