<script setup lang="ts">import { toRef as _toRef, ref as _ref } from 'vue';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { useSharedData } from '@consumer/composables/sharedData'
import { visit } from '@corp/services/api'

const __$temp_1 = (useSharedData()),
  currentUser = _toRef(__$temp_1, 'currentUser');

const popoverToggle = _ref<InstanceType<typeof PopoverButton>>()

function goToPage (event: Event) {
  event.preventDefault()
  popoverToggle.value?.$el?.click()
  const { href } = (event.target as HTMLLinkElement)
  return visit(href)
}
</script>

<template>
  <Popover v-slot="{ open }" class="hidden lg:block">
    <PopoverButton ref="popoverToggle" as="a" class="cursor-pointer flex items-center justify-center gap-1.5">
      <button
        name="my-account-dropdown-button"
        class="rounded-1 bg-primary-base text-white w-28px h-28px flex items-center justify-center"
      >
        <template v-if="currentUser?.initials">{{ currentUser.initials }}</template>
        <GIcon v-else name="new-user" color="white" size="24px"/>
      </button>
      <GIcon
        :name="open ? 'carousel-up' :'carousel-down'"
        class="stroke-navy stroke-2 fill-grey-500"
        style=" height: 15px;width: 10px;"
      />
    </PopoverButton>
    <transition
      enterActiveClass="transition duration-200 ease-out"
      enterFromClass="translate-y-1 opacity-0"
      enterToClass="translate-y-0 opacity-100"
      leaveActiveClass="transition duration-150 ease-in"
      leaveFromClass="translate-y-0 opacity-100"
      leaveToClass="translate-y-1 opacity-0"
    >
      <PopoverPanel
        id="logged-in-dropdown"
        class="
            absolute top-40px right-10px  bg-white z-1000 w-full shadow-sm
            border-1 border-solid border-grey-400 rounded-4px
            flex flex-col
            rounded-lg w-235px bg-white
            text-16px
          "
      >
        <div class="flex flex-col gap-6 my-8 mx-10">
          <a
            href="/my-account/purchases"
            class="flex items-center justify-start gap-3
              hover:underline hover:underline-offset-4"
            @click="goToPage"
          >
            <GIcon name="new-cards" size="24px" color="navy"/>
            Purchases
          </a>
          <a
            href="/my-account/profile"
            class="flex items-center justify-start gap-3
              hover:underline hover:underline-offset-4"
            @click="goToPage"
          >
            <GIcon name="new-user-list" size="24px" color="navy"/>
            Profile
          </a>
          <a
            href="/my-account/billing"
            class="flex items-center justify-start gap-3
              hover:underline hover:underline-offset-4"
            @click="goToPage"
          >
            <GIcon name="new-credit-card" size="24px" color="navy"/>
            Billing
          </a>
          <a
            href="/my-account/settings"
            class="flex items-center justify-start gap-3
              hover:underline hover:underline-offset-4"
            @click="goToPage"
          >
            <GIcon name="new-gear" size="24px" color="navy"/>
            Settings
          </a>
          <GDivider/>
          <a
            href="/signout"
            class="flex items-center justify-start gap-3
              hover:underline hover:underline-offset-4"
            @click="goToPage"
          >
            <GIcon name="new-sign-out" size="24px" color="navy"/>
            Log Out
          </a>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
