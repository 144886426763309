<script lang="ts" setup>
import MobileSidebarLink from '@consumer/modules/layout/MobileSidebarLink.vue'
import type SubNavList from '@consumer/types/SubNavList'

defineProps<{
  open: boolean
  title: string
  lists: Array<SubNavList>
}>()

const activeList = ref<SubNavList>()

const emit = defineEmits<{(e: 'update:open', value: boolean): void }>()
</script>

<template>
  <div v-show="open" class="absolute inset-0 bg-white px-8 py-4">
    <template v-if="activeList">
      <MobileSidebarLink
        class="border-none"
        style=" align-items: start; margin-top: 7px; min-height: 80px;"
        @click="activeList = undefined"
      >
        <GIcon name="carousel-left"/>
        {{ activeList.title }}
      </MobileSidebarLink>
    </template>
    <template v-else>
      <MobileSidebarLink
        class="border-none"
        style=" align-items: start; margin-top: 7px; min-height: 80px;"
        @click="emit('update:open', false)"
      >
        <GIcon name="carousel-left"/>
        {{ title }}
      </MobileSidebarLink>
    </template>

    <div class="flex flex-col">
      <template v-if="activeList">
        <MobileSidebarLink v-for="item, i in activeList.links" :key="i" :href="item.path">
          {{ item.name }}
        </MobileSidebarLink>
      </template>
      <template v-else>
        <MobileSidebarLink v-for="category, i in lists" :key="i" expandable @click="activeList=category">
          {{ category.title }}
        </MobileSidebarLink>
      </template>
    </div>
  </div>
</template>
